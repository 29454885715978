<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card
					.clearfix.d-flex.j-between(slot="header")
						h2.no-line-height.text-left Lead
						.extra
</template>

<style lang="scss">

</style>

<script>
export default {

}
</script>
